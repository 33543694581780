import HttpFactory from '../../factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { type ISearchableCategoryFields } from '~/repository/modules/category/types/categoryTypes'
import { CATEGORIES } from '~/constants/apiPaths/categories/paths'
import { type ICategoryPayload } from '~/store/categories/types/categoryTypes'

class CategoriesModule extends HttpFactory {
  async getAll(extras: IExtrasFetch, searchCriteria: ISearchableCategoryFields, signal?: Nullable<AbortSignal>): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${CATEGORIES}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }

  async create(category: ICategoryPayload, extras: IExtrasFetch): Promise<object> {
    // @ts-ignore
    delete category.Id

    return await this.call<object>('POST', `${this.hostname}${CATEGORIES}`, category, extras)
  }

  async update(category: ICategoryPayload, extras: IExtrasFetch): Promise<object> {
    return await this.call<object>('PUT', `${this.hostname}${CATEGORIES}`, category, extras)
  }

  async delete(extras: IExtrasFetch, categoryId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${CATEGORIES}`,
      {
        Id: categoryId,
      },
      extras,
    )
  }
}

export default CategoriesModule
