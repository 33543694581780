import HttpFactory from '../../factory'
import { IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { ISearchableAttributeFields } from '~/repository/modules/attribute/types/attributeTypes'
import { ATTRIBUTES } from '~/constants/apiPaths/attributes/paths'
import { IAttributePayload } from '~/store/attributes/types/attributeTypes'

class AttributesModule extends HttpFactory {
  async getAll(extras: IExtrasFetch, searchCriteria: ISearchableAttributeFields, signal?: Nullable<AbortSignal>): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${ATTRIBUTES}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }

  async create(attribute: IAttributePayload, extras: IExtrasFetch): Promise<object> {
    // @ts-ignore
    delete attribute.Id

    return await this.call<object>('POST', `${this.hostname}${ATTRIBUTES}`, attribute, extras)
  }

  async update(attribute: IAttributePayload, extras: IExtrasFetch): Promise<object> {
    return await this.call<object>('PUT', `${this.hostname}${ATTRIBUTES}`, attribute, extras)
  }

  async delete(extras: IExtrasFetch, attributeId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${ATTRIBUTES}`,
      {
        Id: attributeId,
      },
      extras,
    )
  }
}

export default AttributesModule
