import HttpFactory from '~/repository/factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import { INVOICES_PAYMENT } from '~/constants/apiPaths/invoicePayments/paths'
import { type IUpdateInvoicePaymentPayload } from '~/store/invoicePayments/types/invoicePaymentTypes'
import removeEmpty from '~/helpers/helpers'
import type { ISearchableInvoicePaymentFields } from '~/repository/modules/invoicePayments/types/invoicePaymentTypes'

class InvoiceModule extends HttpFactory {
  async getAll(extras: IExtrasFetch, searchCriteria: ISearchableInvoicePaymentFields, signal: AbortSignal | null = null): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${INVOICES_PAYMENT}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }

  async updatePayPayment(invoicePayment: IUpdateInvoicePaymentPayload, extras: IExtrasFetch): Promise<object> {
    return await this.call<object>('PUT', `${this.hostname}${INVOICES_PAYMENT}`, invoicePayment, extras)
  }
}
export default InvoiceModule
