export default function removeEmpty(obj: object) {
  return Object.keys(obj)
    .filter(function (k) {
      // @ts-ignore
      return obj[k] != null
    })
    .reduce(function (acc, k) {
      // @ts-ignore
      acc[k] = typeof obj[k] === 'object' ? removeEmpty(obj[k]) : obj[k]
      return acc
    }, {})
}

export function inputNotAllowedEmptyStringOnKeyPress(e: KeyboardEvent) {
  const keyCode = e.keyCode ? e.keyCode : e.which
  if (keyCode === 32) {
    e.preventDefault()
  }
  if (e.key === ' ') {
    e.preventDefault()
  }
}

export function numberWithCommas(x: number) {
  return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}
