import type { $Fetch } from 'ofetch'

class HttpFactory {
  private $fetch: $Fetch
  public hostname: string

  constructor(fetcher: $Fetch) {
    const config = useRuntimeConfig()
    this.$fetch = fetcher
    // @ts-ignore
    this.hostname = config.public.API_BASE_URL
  }

  /**
   * method - GET, POST, PUT
   * URL
   **/
  async call<T>(method: string, url: string, data?: object | null, extras = {}, signal?: Nullable<AbortSignal>): Promise<T> {
    return await this.$fetch(url, { method, body: data, ...extras, signal })
  }
}

export default HttpFactory
