import HttpFactory from '../../factory'
import { IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { ISearchableSubCategoryFields } from '~/repository/modules/subCategory/types/subCategoryTypes'
import { SUB_CATEGORIES } from '~/constants/apiPaths/subCategories/paths'
import { ISubCategoryPayload } from '~/store/subCategories/types/subCategoryTypes'

class SubCategoriesModule extends HttpFactory {
  async getAll(extras: IExtrasFetch, searchCriteria: ISearchableSubCategoryFields, signal?: Nullable<AbortSignal>): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${SUB_CATEGORIES}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }

  async create(subCategory: ISubCategoryPayload, extras: IExtrasFetch): Promise<object> {
    // @ts-ignore
    delete subCategory.Id

    return await this.call<object>('POST', `${this.hostname}${SUB_CATEGORIES}`, subCategory, extras)
  }

  async update(subCategory: ISubCategoryPayload, extras: IExtrasFetch): Promise<object> {
    return await this.call<object>('PUT', `${this.hostname}${SUB_CATEGORIES}`, subCategory, extras)
  }

  async delete(extras: IExtrasFetch, subCategoryId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${SUB_CATEGORIES}`,
      {
        Id: subCategoryId,
      },
      extras,
    )
  }
}

export default SubCategoriesModule
