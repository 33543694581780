import type IAuthState from '~/store/auth/interfaces/IAuthState'
// import { ISimpleUser } from '~/store/users/types/userTypes'

export const getters = {
  getUser: (state: IAuthState) => () => {
    return state.user
  },
  loggedUserIsAdmin: (state: IAuthState) => () => {
    const thereHaveRoles = state.user?.roles != null ? state.user!.roles!.length > 0 : false
    if (thereHaveRoles) {
      // 1 is the id of an admin role and doesn't have a company
      return state.user!.roles![0].id === '1' && state.user!.company === null
    }
    return false
  },
  isUserLoggedIn(state: IAuthState): boolean {
    return state.token.length > 0
  },
}
