import HttpFactory from '../../factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { type ISearchableBrandFields } from '~/repository/modules/brand/types/brandTypes'
import { BRANDS } from '~/constants/apiPaths/brands/paths'
import { type IBrandPayload } from '~/store/brands/types/brandTypes'

class BrandsModule extends HttpFactory {
  async getAll(extras: IExtrasFetch, searchCriteria: ISearchableBrandFields, signal?: Nullable<AbortSignal>): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${BRANDS}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }

  async create(brand: IBrandPayload, extras: IExtrasFetch): Promise<object> {
    // @ts-ignore
    delete brand.Id

    return await this.call<object>('POST', `${this.hostname}${BRANDS}`, brand, extras)
  }

  async update(brand: IBrandPayload, extras: IExtrasFetch): Promise<object> {
    return await this.call<object>('PUT', `${this.hostname}${BRANDS}`, brand, extras)
  }

  async delete(extras: IExtrasFetch, brandId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${BRANDS}`,
      {
        Id: brandId,
      },
      extras,
    )
  }
}

export default BrandsModule
