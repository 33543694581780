// interfaces
import { Status } from '~/constants/general/Status'
import { useLoginStatus } from '~/composables/auth/LoginStatus'
import type { IUserPayload } from '~/store/users/types/userTypes'
import type { ILoginCredentials, ILoginSuccessResponse } from '~/repository/modules/auth/types/loginTypes'

/**
 * This resets the status to the initial status
 */
const resetStatusLogin = () => {
  const { changeLoginStatus } = useLoginStatus()
  setTimeout(() => changeLoginStatus(Status.INITIAL), 3000)
}

export const actions = {
  async login(body: ILoginCredentials) {
    const loginStatus = useLoginStatus()
    const { $api } = useNuxtApp()
    let response
    loginStatus.changeLoginStatus(Status.LOADING)
    try {
      const credentials: ILoginCredentials = {
        ...body,
      }
      response = await $api.auth.login(credentials)
      // eslint-disable-next-line no-console
      console.log(response)
      const typedResponse: ILoginSuccessResponse = response as ILoginSuccessResponse
      this.setAuthUser(typedResponse)
      loginStatus.changeLoginStatus(Status.SUCCESS)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      loginStatus.changeLoginStatus(Status.FAILED)
      resetStatusLogin()
    }
  },
  setAuthUser(authUser: ILoginSuccessResponse) {
    // @ts-ignore
    this.user = authUser.loggedUser
    // @ts-ignore
    this.token = authUser.token
  },
  reset() {
    Object.assign(this, {
      token: '',
      user: <IUserPayload | null>null,
    })
  },
  initializeStore() {
    // Your initialization logic here
    // @ts-ignore
    this.isInitialized = true
  },
}
