import HttpFactory from '~/repository/factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import { INVOICES_PAYMENT_TIMELINE_ITEM } from '~/constants/apiPaths/invoicePaymentsTimelineItems/paths'
import removeEmpty from '~/helpers/helpers'
import type { ISearchableInvoicePaymentTimelineItemFields } from '~/repository/modules/invoicePaymentTimelineItemPaymentTimelineItems/types/invoicePaymentTimelineItemTypes'

class InvoicePaymentTimelineItemModule extends HttpFactory {
  async getAll(extras: IExtrasFetch, searchCriteria: ISearchableInvoicePaymentTimelineItemFields, signal: AbortSignal | null = null): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${INVOICES_PAYMENT_TIMELINE_ITEM}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }
}
export default InvoicePaymentTimelineItemModule
