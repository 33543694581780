import HttpFactory from '~/repository/factory'
import { IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { PRODUCTS } from '~/constants/apiPaths/products/paths'
import { IProductPayload } from '~/store/products/types/productTypes'
import { ISearchableProductFields } from '~/repository/modules/products/types/productTypes'

class ProductModule extends HttpFactory {
  async getAll(extras: IExtrasFetch, searchCriteria: ISearchableProductFields): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${PRODUCTS}?${new URLSearchParams(urlParams)}`, null, extras)
  }

  async create(product: IProductPayload, extras: IExtrasFetch): Promise<object> {
    // @ts-ignore
    delete product.Id

    return await this.call<object>('POST', `${this.hostname}${PRODUCTS}`, product, extras)
  }

  async update(product: IProductPayload, extras: IExtrasFetch): Promise<object> {
    return await this.call<object>('PUT', `${this.hostname}${PRODUCTS}`, product, extras)
  }

  async delete(extras: IExtrasFetch, productId: string): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${PRODUCTS}`,
      {
        Id: parseInt(productId),
      },
      extras,
    )
  }
}
export default ProductModule
