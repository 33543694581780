import { $fetch, type FetchOptions } from 'ofetch'
import { defineNuxtPlugin } from '#app'
import AuthModule from '~/repository/modules/auth/auth'
import CustomersRepository from '~/repository/modules/customers/customersRepository'
import ProductsRepository from '~/repository/modules/products/productsRepository'
import CompaniesRepository from '~/repository/modules/company/companiesRepository'
import CategoriesRepository from '~/repository/modules/category/categoriesRepository'
import SubCategoriesRepository from '~/repository/modules/subCategory/subCategoriesRepository'
import BrandsRepository from '~/repository/modules/brand/brandsRepository'
import AttributeRepository from '~/repository/modules/attribute/attributeRepository'
import AttributeValuesRepository from '~/repository/modules/attributeValue/attributeValuesRepository'
import WarehouseRepository from '~/repository/modules/warehouse/warehouseRepository'
import InvoicesRepository from '~/repository/modules/invoices/invoicesRepository'
import InvoicePaymentsRepository from '~/repository/modules/invoicePayments/invoicePaymentsRepository'
import UsersRepository from '~/repository/modules/user/usersRepository'
import InvoicePaymentTimelineItemModule from '~/repository/modules/invoicePaymentTimelineItemPaymentTimelineItems/invoicePaymentsTimelineItemRepository'

/** ApiInstance interface provides us with good typing */
interface IApiInstance {
  auth: AuthModule
  customersRepository: CustomersRepository
  productsRepository: ProductsRepository
  companiesRepository: CompaniesRepository
  categoriesRepository: CategoriesRepository
  subCategoriesRepository: SubCategoriesRepository
  brandsRepository: BrandsRepository
  attributeRepository: AttributeRepository
  attributeValueRepository: AttributeValuesRepository
  warehouseRepository: WarehouseRepository
  invoicesRepository: InvoicesRepository
  invoicePaymentsRepository: InvoicePaymentsRepository
  usersRepository: UsersRepository
  invoicePaymentTimelineItemRepository: InvoicePaymentTimelineItemModule
}

export default defineNuxtPlugin((nuxtApp) => {
  const fetchOptions: FetchOptions = {
    // @ts-ignore
    baseURL: nuxtApp.$config.API_BASE_URL,
  }

  /** create a new instance of $fetcher with a custom option */
  const apiFetcher = $fetch.create(fetchOptions)

  /** an object containing all repositories we need to expose */
  const modules: IApiInstance = {
    auth: new AuthModule(apiFetcher),
    customersRepository: new CustomersRepository(apiFetcher),
    productsRepository: new ProductsRepository(apiFetcher),
    companiesRepository: new CompaniesRepository(apiFetcher),
    categoriesRepository: new CategoriesRepository(apiFetcher),
    subCategoriesRepository: new SubCategoriesRepository(apiFetcher),
    brandsRepository: new BrandsRepository(apiFetcher),
    attributeRepository: new AttributeRepository(apiFetcher),
    attributeValueRepository: new AttributeValuesRepository(apiFetcher),
    warehouseRepository: new WarehouseRepository(apiFetcher),
    invoicesRepository: new InvoicesRepository(apiFetcher),
    invoicePaymentsRepository: new InvoicePaymentsRepository(apiFetcher),
    usersRepository: new UsersRepository(apiFetcher),
    invoicePaymentTimelineItemRepository: new InvoicePaymentTimelineItemModule(apiFetcher),
  }

  return {
    provide: {
      api: modules,
    },
  }
})
